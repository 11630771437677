export const chessPieces = [
  { name: 'White king', text: '♔' },
  { name: 'White queen', text: '♕' },
  { name: 'White rook', text: '♖' },
  { name: 'White bishop', text: '♗' },
  { name: 'White knight', text: '♘' },
  { name: 'White pawn', text: '♙' },
  { name: 'Black king', text: '♚' },
  { name: 'Black queen', text: '♛' },
  { name: 'Black rook', text: '♜' },
  { name: 'Black bishop', text: '♝' },
  { name: 'Black knight', text: '♞' },
  { name: 'Black pawn', text: '♟' }
]

export type Pieces = 'rook' | 'knight' | 'bishop' | 'queen' | 'king' | 'pawn'

export const pieces: Record<'white' | 'black', Record<Pieces, string>> = {
  white: { rook: '♖', knight: '♘', bishop: '♗', queen: '♕', king: '♔', pawn: '♙' },
  black: { rook: '♜', knight: '♞', bishop: '♝', queen: '♛', king: '♚', pawn: '♟' }
}

export const monospacePieces: Record<'white' | 'black', Record<Pieces, string>> = {
  white: { rook: '🅁', knight: '🄽', bishop: '‍🄱', queen: '🅀', king: '🄺', pawn: '🄿' },
  black: { rook: '🆁', knight: '🅽', bishop: '🅱', queen: '🆀', king: '🅺', pawn: '🅿' }
}

export const emojiPieces: Record<'white' | 'black', Record<Pieces, string>> = {
  white: { rook: '🏰', knight: '🏇', bishop: '‍🤺', queen: '👸', king: '🤴', pawn: '👮' },
  black: { rook: '🐙', knight: '🦐', bishop: '🦑‍', queen: '🦞', king: '🦀', pawn: '🍤' }
}

export const border = {
  top: [
    '╔',
    '═',
    '╤',
    '═',
    '╤',
    '═',
    '╤',
    '═',
    '╤',
    '═',
    '╤',
    '═',
    '╤',
    '═',
    '╤',
    '═',
    '╗'
  ],
  sides: ['╟', '║', '╢'],
  bottom: [
    '╚',
    '═',
    '╧',
    '═',
    '╧',
    '═',
    '╧',
    '═',
    '╧',
    '═',
    '╧',
    '═',
    '╧',
    '═',
    '╧',
    '═',
    '╝'
  ]
}

export const smallBorder = {
  top: ['╔', '═', '═', '═', '═', '═', '═', '═', '═', '╗'],
  sides: ['╟', '║', '╢'],
  bottom: ['╚', '═', '═', '═', '═', '═', '═', '═', '═', '╝']
}

export const text = {
  top: ['╔', '═', '═', '═', '═', '═', '═', '═', '═', '╗'],
  sides: ['╟', '║', '╢'],
  bottom: ['╚', '═', '═', '═', '═', '═', '═', '═', '═', '╝']
}

export const crowns = [
  { name: 'Black king', text: '♚' },
  { name: 'White king', text: '♔' },
  { name: 'White queen', text: '♕' },
  { name: 'Black queen', text: '♛' },
  { name: 'Crown emoji', text: '👑' },
  { name: 'Prince emoji', text: '🤴' },
  { name: 'Princess emoji', text: '👸' },
  { name: 'European castle emoji', text: '🏰' },
  { name: 'Japanese castle emoji', text: '🏯' },
  { name: 'Horse emoji', text: '🐴' }
]

export type Square = { piece: Pieces | '', white: boolean }
export type SquareWithPiece = { piece: Pieces, white: boolean }
export type Board = Square[]

export const pieceShelf: SquareWithPiece[] = [
  { piece: 'pawn', white: false },
  { piece: 'rook', white: false },
  { piece: 'knight', white: false },
  { piece: 'bishop', white: false },
  { piece: 'queen', white: false },
  { piece: 'king', white: false },
  { piece: 'pawn', white: true },
  { piece: 'rook', white: true },
  { piece: 'knight', white: true },
  { piece: 'bishop', white: true },
  { piece: 'queen', white: true },
  { piece: 'king', white: true }
]

export const initialBoard: Board = [
  { piece: 'rook', white: false },
  { piece: 'knight', white: false },
  { piece: 'bishop', white: false },
  { piece: 'queen', white: false },
  { piece: 'king', white: false },
  { piece: 'bishop', white: false },
  { piece: 'knight', white: false },
  { piece: 'rook', white: false },
  ...Array(8).fill({ piece: 'pawn', white: false }),
  ...Array(32).fill({ piece: '', white: false }),
  ...Array(8).fill({ piece: 'pawn', white: true }),
  { piece: 'rook', white: true },
  { piece: 'knight', white: true },
  { piece: 'bishop', white: true },
  { piece: 'queen', white: true },
  { piece: 'king', white: true },
  { piece: 'bishop', white: true },
  { piece: 'knight', white: true },
  { piece: 'rook', white: true }
]

export const tags = [
  { name: 'Unicode' },
  { name: 'Tools' },
  { name: 'Symbols' },
  { name: 'Emoji' },
  { name: 'Copy and paste' },
  { name: 'Memes' }
]
