import React from 'react'
import { useDrop } from 'react-dnd'

import styles from './styles.module.scss'

type RemovePiecesAreaProps = {
  removePiece: (index: number) => void
  isDragging: boolean
  setIsDragging: (isDragging: boolean) => void
}

export const RemovePiecesArea: React.FC<RemovePiecesAreaProps> = ({ isDragging, removePiece, setIsDragging }) => {
  const [, drop] = useDrop(
    () => ({
      accept: 'piece',
      drop: ({ index: sourceIndex, fromShelf }: { index: number, fromShelf: boolean }) => {
        if (!fromShelf) {
          removePiece(sourceIndex)
        }
        setIsDragging(false)
      },
      canDrop: () => true
    }),
    []
  )

  if (!isDragging) {
    return null
  }

  return <div ref={drop} className={styles['discard-sign']}>REMOVE 🗑️</div>
}
