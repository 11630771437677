import React, { useEffect } from 'react'
import { useDrag } from 'react-dnd'

import { pieces } from '../../../../constants'
import type { SquareWithPiece } from '../../../../constants'

type ChessPieceProps = {
  square: SquareWithPiece
  index: number
  fromShelf: boolean
  setIsDragging: (isDragging: boolean) => void
}

export const ChessPiece: React.FC<ChessPieceProps> = ({ square, index, fromShelf, setIsDragging }) => {
  const [{ isDragging }, drag] = useDrag(() => ({
    type: 'piece',
    item: { index, square, fromShelf },
    collect: monitor => ({ isDragging: monitor.isDragging() })
  }), [])

  useEffect(() => {
    setIsDragging(isDragging)
  }, [isDragging])

  return (
    <div style={{ transform: 'translate(0, 0)' }} ref={drag}>
      {pieces.white[square.piece]}
    </div>
  )
}
