import React, { useEffect, useState, useRef } from 'react'
import cn from 'classnames'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'

import { Card } from '../../../components/Card'
import { Button } from '../../../components/Button'
import { Title } from '../../../components/Title'
import { TextWithImage } from '../../../components/TextWithImage'
import { TextGroup } from '../../../components/TextGroup'
import { VariantsContainer } from '../../../components/VariantsContainer'

import { RemovePiecesArea } from './components/RemovePiecesArea'
import { ChessSquare } from './components/ChessSquare'

import { CopyAndPaste } from '..'

import { updateBoardSquare, monospaced, monoWithNumbers, smaller, textBoard, emoji } from './functions'
import { generateTableChessboard } from './functions/table-chessboard'

import { chessPieces, crowns, pieceShelf, initialBoard, tags } from './constants'
import type { Board } from './constants'

import styles from './styles.module.scss'

const boardStyles = [
  {
    fn: monospaced,
    name: 'Monospace board'
  },
  {
    fn: monoWithNumbers,
    name: 'Monospace board with coordinates'
  },
  {
    fn: smaller,
    name: 'Small monospace board'
  },
  {
    fn: textBoard,
    name: 'Enclosed alphanumerics'
  },
  {
    fn: emoji,
    name: 'Rise of the Crab King'
  }
]

const ChessSymbols: React.FC = () => {
  const [board, setBoard] = useState<Board>(initialBoard)
  const [isDragging, setIsDragging] = useState(false)

  const clearBoard = (): void => setBoard(Array(64).fill({ piece: '', white: false }))
  const resetBoard = (): void => setBoard(initialBoard)
  const flipColors = (): void => setBoard(board.map((square) => square.piece ? { ...square, white: !square.white } : square))
  const removePiece = (index: number): void => setBoard(board => updateBoardSquare(board, { piece: '', white: false }, index))

  useEffect(() => {
    generateTableChessboard(ref)
  }, [])

  const ref = useRef(null)

  return (
    <CopyAndPaste tags={tags} subtitleClassName={styles.subtitle}>
      <VariantsContainer className={styles['variants-container']}>
        {chessPieces.map(variant => <Card className={styles.card} key={variant.name} title={variant.name} text={variant.text} textFontSize={32} textAlignCenter />)}
      </VariantsContainer>
      <Title className={styles['crown-title']}>Crown symbols</Title>
      <small className={styles['crown-subtitle']}>and other chess-related Unicode characters</small>
      <VariantsContainer className={styles['variants-container']}>
        {crowns.map(variant => <Card className={styles.card} key={variant.name} title={variant.name} text={variant.text} textFontSize={32} textAlignCenter />)}
      </VariantsContainer>
      <Title size={3} className={styles['chessboard-title']}>Chess symbols and Unicode</Title>
      <div className={styles['chessboard-container']}>
        <div className={styles.left}>
          <DndProvider backend={HTML5Backend}>
            <div className={styles['input-board']}>
              <div className={styles['normal-board']}>
                {board.map((square, i) => <ChessSquare key={`board-square-${i}`} setBoard={setBoard} square={square} index={i} setIsDragging={setIsDragging} />)}
              </div>
            </div>
            <div className={styles['piece-shelf-container']}>
              <div className={cn(styles['piece-shelf'])}>
                {pieceShelf.map((piece, index) => (
                  <ChessSquare key={index} setBoard={setBoard} square={piece} index={index} fromShelf setIsDragging={setIsDragging} />
                ))}
              </div>
              <RemovePiecesArea isDragging={isDragging} removePiece={removePiece} setIsDragging={setIsDragging} />
            </div>
          </DndProvider>
        </div>
        <div className={styles.right}>
          <div className={styles['chessboard-text-container']}>
            <p className={styles.text}>
              Typefaces with chess symbols existed long before digital type. Chess players used these fonts in illustrations and shorthand notation of chess games.
            </p>
            <p className={styles.text}>
              So it’s not surprising that Unicode added chess symbols shortly after it was founded. Unicode chess symbols allow coders to create smaller chess programs by saving memory used to store the graphics for each piece.
            </p>
          </div>
          <div className={styles.buttons}>
            <Button onClick={clearBoard}>Clear</Button>
            <Button onClick={resetBoard}>Reset</Button>
            <Button onClick={flipColors}>Flip</Button>
          </div>
        </div>
      </div>
      <div className={styles['board-variants-container']}>
        {boardStyles.map(variant => (
          <Card className={styles['board-card']} key={variant.name} title={variant.name} text={variant.fn(board).join('\n')} textFontSize={20} textAlignCenter />
        ))}
      </div>
      <TextWithImage
        title='Toledo Nanochess'
        image={<div id="nanochess" ref={ref} />}
        style={{ marginTop: 64 }}
      >
        <>
          <p>
            Toledo Nanochess is one example of these tiny chess programs. This one was written in JavaScript in only 2.2kb by Óscar Toledo G.
          </p>
          <p className={styles['dark-text']}>
            It’s been changed slightly to work with the JavaScript framework used by this website.
            You can find the <a href='https://nanochess.org/chess.html'>original here</a>.
          </p>
          <p className={styles['dark-text']}>
            Over the years, Unicode added other game symbols, including playing cards 🂡, dice ⚅, dominos 🁌, Mahjong 🀅, Shogi ☖, Draughts ⛀, and Go ⚆.
          </p>
        </>
      </TextWithImage>
      <TextGroup title='Alternative chess symbols' style={{ marginTop: 48 }}>
        <p>
          There are hundreds of variations on chess and chess puzzles.
          Many of these variations use different chess symbols, but they aren’t included here since rendering them requires installing a special font.
        </p>
        <p>
          You can read more about alternative chess symbols on <a href='https://en.wikipedia.org/wiki/Chess_Symbols'>Wikipedia</a>.
        </p>
      </TextGroup>
      <TextGroup title='How to use Unicode chess symbols' style={{ marginTop: 32 }}>
        <p>To use these symbols, simply copy them to your clipboard and paste them as you would any text online.</p>
      </TextGroup>
    </CopyAndPaste>
  )
}

export default ChessSymbols
