import { monospacePieces, emojiPieces, pieces, border, smallBorder } from '../constants'

import type { Board, Square } from '../constants'

export const isBlack = (i: number): boolean => {
  return parseInt(i / 8 + i as any) % 2 === 0
}

export const updateBoardSquare = (board: Board, square: Square, index: number): Board => {
  return [
    ...board.slice(0, index),
    square,
    ...board.slice(index + 1)
  ]
}

const createBoard = (board: Board): Square[][] => {
  return board.map((_, i) => {
    return i % 8 === 0 ? board.slice(i, i + 8) : null
  }).filter(e => e) as Square[][]
}

const monospacedBoard = (board: Board): string[][] => {
  const chessboard = [
    ['', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', ''],
    ['', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', ''],
    ['', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', ''],
    ['', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', ''],
    ['', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', ''],
    ['', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', ''],
    ['', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', ''],
    ['', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', ''],
    ['', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', ''],
    ['', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', ''],
    ['', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', ''],
    ['', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', ''],
    ['', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', ''],
    ['', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', ''],
    ['', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', ''],
    ['', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', ''],
    ['', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '']
  ]

  createBoard(board).forEach((row, ri) => {
    const rinum = ri * 2 + 1

    row.forEach((square, si) => {
      let space = '\u2000'
      if (square.piece) {
        space = pieces[square.white ? 'white' : 'black'][square.piece]
      } else if ((si + 1 - ((ri + 1) % 2)) % 2) {
        space = '░'
      }

      const sinum = si * 2 + 1

      // Build square top
      chessboard[rinum - 1][sinum - 1] = '┼'
      chessboard[rinum - 1][sinum] = '─'
      chessboard[rinum - 1][sinum + 1] = '┼'

      // build square and square sides
      chessboard[rinum][sinum - 1] = '│'
      chessboard[rinum][sinum] = space
      chessboard[rinum][sinum + 1] = '│'

      // build square bottom
      chessboard[rinum + 1][sinum - 1] = '┼'
      chessboard[rinum + 1][sinum] = '─'
      chessboard[rinum + 1][sinum + 1] = '┼'
    })
  })

  // build border top
  chessboard[0].forEach((_, i) => {
    chessboard[0][i] = border.top[i]
  })

  // build border bottom
  chessboard[chessboard.length - 1].forEach((_, i) => {
    chessboard[chessboard.length - 1][i] = border.bottom[i]
  })

  // build side borders
  chessboard.forEach((row, ri) => {
    row.forEach((_, ci) => {
      if (ri > 0 && ri < chessboard.length - 1) {
        if (ci === 0) {
          if (!(ri % 2)) {
            chessboard[ri][ci] = border.sides[0]
          } else {
            chessboard[ri][ci] = border.sides[1]
          }
        } else if (ci === row.length - 1) {
          if (!(ri % 2)) {
            chessboard[ri][ci] = border.sides[2]
          } else {
            chessboard[ri][ci] = border.sides[1]
          }
        }
      }
    })
  })
  return chessboard
}

export const monospaced = (board: Board): string[] => {
  return monospacedBoard(board).map(arr => arr.join(''))
}

export const monoWithNumbers = (board: Board): string[] => {
  const numbers = ['8', '7', '6', '5', '4', '3', '2', '1']
  const newArr = []

  monospaced(board).forEach((arr, i) => {
    if (i === 0) {
      arr = arr + '╮'
    } else if (!((i + 1) % 2)) {
      const num = (i + 1) / 2 - 1
      arr = arr + numbers[num]
    } else {
      arr = arr + '┊'
    }
    newArr.push(arr)
  })
  const letters = '╰a┈b┈c┈d┈e┈f┈g┈h┈╯'

  newArr.push(letters)
  return newArr
}

const smallerBoard = (board: Board): string[][] => {
  const chessboard = [
    ['', '', '', '', '', '', '', '', '', ''],
    ['', '', '', '', '', '', '', '', '', ''],
    ['', '', '', '', '', '', '', '', '', ''],
    ['', '', '', '', '', '', '', '', '', ''],
    ['', '', '', '', '', '', '', '', '', ''],
    ['', '', '', '', '', '', '', '', '', ''],
    ['', '', '', '', '', '', '', '', '', ''],
    ['', '', '', '', '', '', '', '', '', ''],
    ['', '', '', '', '', '', '', '', '', ''],
    ['', '', '', '', '', '', '', '', '', '']
  ]

  createBoard(board).forEach((row, ri) => {
    const rinum = ri + 1

    row.forEach((square, si) => {
      let space = '▒'
      if (square.piece) {
        space = pieces[square.white ? 'white' : 'black'][square.piece]
      } else if ((si + 1 - ((ri + 1) % 2)) % 2) {
        space = '█'
      }

      const sinum = si + 1

      // build square and square sides

      chessboard[rinum][sinum] = space
    })
  })

  // build border top
  chessboard[0].forEach((_, i) => {
    chessboard[0][i] = smallBorder.top[i]
  })

  // build border bottom
  chessboard[chessboard.length - 1].forEach((_, i) => {
    chessboard[chessboard.length - 1][i] = smallBorder.bottom[i]
  })

  // build side borders
  chessboard.forEach((row, ri) => {
    row.forEach((_, ci) => {
      if (ri > 0 && ri < chessboard.length - 1) {
        if (ci === 0) {
          if (!(ri % 2)) {
            chessboard[ri][ci] = smallBorder.sides[1]
          } else {
            chessboard[ri][ci] = smallBorder.sides[1]
          }
        } else if (ci === row.length - 1) {
          if (!(ri % 2)) {
            chessboard[ri][ci] = smallBorder.sides[1]
          } else {
            chessboard[ri][ci] = smallBorder.sides[1]
          }
        }
      }
    })
  })

  return chessboard
}

export const smaller = (board: Board): string[] => {
  return smallerBoard(board).map(arr => arr.join(''))
}

const createTextBoard = (board: Board): string[][] => {
  const chessboard = [
    ['', '', '', '', '', '', '', '', '', ''],
    ['', '', '', '', '', '', '', '', '', ''],
    ['', '', '', '', '', '', '', '', '', ''],
    ['', '', '', '', '', '', '', '', '', ''],
    ['', '', '', '', '', '', '', '', '', ''],
    ['', '', '', '', '', '', '', '', '', ''],
    ['', '', '', '', '', '', '', '', '', ''],
    ['', '', '', '', '', '', '', '', '', ''],
    ['', '', '', '', '', '', '', '', '', ''],
    ['', '', '', '', '', '', '', '', '', '']
  ]

  createBoard(board).forEach((row, ri) => {
    const rinum = ri + 1

    row.forEach((square, si) => {
      let space = '🅇'
      if (square.piece) {
        space = monospacePieces[square.white ? 'white' : 'black'][square.piece]
      } else if ((si + 1 - ((ri + 1) % 2)) % 2) {
        space = '🆇'
      }

      const sinum = si + 1

      // build square and square sides

      chessboard[rinum][sinum] = space
    })
  })

  return chessboard
}

export const textBoard = (board: Board): string[] => {
  return createTextBoard(board).map(arr => arr.join(''))
}

const emojiBoard = (board: Board): string[][] => {
  const chessboard = [
    ['', '', '', '', '', '', '', '', '', ''],
    ['', '', '', '', '', '', '', '', '', ''],
    ['', '', '', '', '', '', '', '', '', ''],
    ['', '', '', '', '', '', '', '', '', ''],
    ['', '', '', '', '', '', '', '', '', ''],
    ['', '', '', '', '', '', '', '', '', ''],
    ['', '', '', '', '', '', '', '', '', ''],
    ['', '', '', '', '', '', '', '', '', ''],
    ['', '', '', '', '', '', '', '', '', ''],
    ['', '', '', '', '', '', '', '', '', '']
  ]

  createBoard(board).forEach((row, ri) => {
    const rinum = ri + 1

    row.forEach((square, si) => {
      let space = '⛰'

      if (square.piece) {
        space = emojiPieces[square.white ? 'white' : 'black'][square.piece]
      } else if ((si + 1 - ((ri + 1) % 2)) % 2) {
        space = '🌊'
      }

      const sinum = si + 1

      // build square and square sides

      chessboard[rinum][sinum] = space
    })
  })

  return chessboard
}

export const emoji = (board: Board): string[] => {
  return emojiBoard(board).map((arr: string[]) => arr.join(''))
}
