import React from 'react'
import type { Dispatch, SetStateAction } from 'react'
import { useDrop } from 'react-dnd'
import cn from 'classnames'

import { ChessPiece } from './components/ChessPiece'

import { updateBoardSquare, isBlack } from '../../functions'
import type { Square, SquareWithPiece, Board } from '../../constants'

import styles from './styles.module.scss'

type ChessSquareProps = {
  square: Square
  index: number
  fromShelf?: boolean
  setBoard: Dispatch<SetStateAction<Board>>
  setIsDragging: (isDragging: boolean) => void
}

type SourceSquareData = { index: number, square: SquareWithPiece, fromShelf: boolean }

export const ChessSquare: React.FC<ChessSquareProps> = ({ square, index, setBoard, fromShelf = false, setIsDragging }) => {
  const [, drop] = useDrop(
    () => ({
      accept: 'piece',
      drop: ({ index: sourceIndex, square: sourceSquare, fromShelf }: SourceSquareData) => {
        setBoard(board => updateBoardSquare(fromShelf ? board : updateBoardSquare(board, { ...sourceSquare, piece: '' }, sourceIndex), { ...sourceSquare }, index))
      },
      canDrop: () => true
    }),
    []
  )

  return (
    <div
      ref={drop}
      className={
        cn(
          styles.square,
          square.white ? styles['text-white'] : styles['text-black'],
          (fromShelf ? !square.white : !isBlack(index)) ? styles['highlight-3'] : styles.blue
        )
      }
    >
      {square.piece ? <ChessPiece square={square as SquareWithPiece} index={index} fromShelf={fromShelf} setIsDragging={setIsDragging} /> : null}
    </div>
  )
}
